import { template as template_99fe10cf5a634ae2af572deb39e8ea0e } from "@ember/template-compiler";
const FKLabel = template_99fe10cf5a634ae2af572deb39e8ea0e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
