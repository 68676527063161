import { template as template_3ccefcd711c84368b656920c0f7e4719 } from "@ember/template-compiler";
const SidebarSectionMessage = template_3ccefcd711c84368b656920c0f7e4719(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
