import { template as template_135c262c35d3461bb3e59f50ccda25f7 } from "@ember/template-compiler";
const FKText = template_135c262c35d3461bb3e59f50ccda25f7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
